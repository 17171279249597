const { createIcons, Mail, ClipboardList, LifeBuoy } = require("lucide");
import "@fontsource/inter"; // Defaults to weight 400.

createIcons({
  icons: {
    Mail,
    ClipboardList,
    LifeBuoy,
  },
});
